/* eslint-disable */
export default [
  // android检测
  {
    name: 'detection',
    key: 'android_detection',
    loc: '/detection/android',
    component: () => import('@/modules/detection-module/android-detection'),
  },
  // ios检测
  {
    name: 'detection_ios',
    key: 'ios_detection',
    loc: '/detection/ios',
    component: () => import('@/modules/detection-module/ios-detection'),
  },
  {
    // 应用合规审查
    name: 'Secret',
    key: 'privacy_application',
    loc: '/secret/list',
    component: () => import('@/modules/privacy-detection-module/application-compliance-review'),
  },
  // 归属企业管理
  {
    name: 'Secret',
    key: 'privacy_company',
    loc: '/secret/management',
    component: () => import('@/modules/privacy-detection-module/business-management'),
  },
  // 应用权限库
  {
    name: 'Secret',
    key: 'privacy_permission',
    loc: '/secret/powerconfig',
    component: () => import('@/modules/privacy-detection-module/powerconfig'),
  },
  // 权限行为规则
  {
    name: 'ruleconfig',
    key: 'privacy_permission_behavior',
    loc: '/secret/ruleconfig',
    component: () => import('@/modules/privacy-detection-module/ruleConfig'),
  },
  // 隐私行为库
  {
    name: 'Secret',
    key: 'privacy_behavior',
    loc: '/secret/actionconfig',
    component: () => import('@/modules/privacy-detection-module/behaviorconfig'),
  },
  {
    // 隐私文本规则
    name: 'Secret',
    key: 'privacy_text',
    loc: '/secret/textdetection',
    component: () => import('@/modules/privacy-detection-module/TextDetection'),
  },
  // SDK基础库
  {
    name: 'Secret',
    key: 'privacy_sdk',
    loc: '/secret/sdkbank',
    component: () => import('@/modules/privacy-detection-module/SdkBank'),
  },
  // SDK加固
  {
    name: 'SdkEncryptAPK',
    loc: '/sdk/encrypt',
    key: 'sdk_encrypt',
    component: () => import('@/modules/sdk-encrypt-module/sdk-encrypt'),
  },
  // SDK加固策略
  {
    name: 'SdkEncryptAPKPolicy',
    loc: '/sdk/encryptPolicy',
    key: 'sdk_policy',
    component: () => import('@/modules/sdk-encrypt-module/sdk-encrypt-policy'),
  },
  // 入口类管理
  {
    name: 'SdkEncrypEntraceClass',
    loc: '/sdk/entraceClass',
    key: 'sdk_entrace_class_manage',
    component: () => import('@/modules/sdk-encrypt-module/entrance-class'),
  },
  {
    name: 'SdkEncryptAPK',
    loc: '/sdk/policy',
    key: 'sdk_policy',
    component: () => import('@/modules/sdk-encrypt-module/sdk-encrypt-policy'),
  },
  // Android加固
  {
    name: 'EncryptAPK',
    loc: '/encrypt/android',
    key: 'android_encrypt',
    component: () => import('@/modules/android-encrypt-module/android-encrypt'),
  },
  // 加固策略
  {
    name: 'mencelog3',
    loc: '/encrypt/policy',
    key: 'protect_policy',
    component: () => import('@/modules/android-encrypt-module/encrypt-policy-new'),
  },
  // 应用签名
  {
    name: '',
    loc: '/encrypt/sign',
    key: 'application_sign',
    component: () => import('@/modules/android-encrypt-module/app-sign'),
  },
  // 签名文件配置
  {
    name: '',
    loc: '/encrypt/sign-config',
    key: 'sign_file_config',
    component: () => import('@/modules/android-encrypt-module/sign-file-config'),
  },
  // 安卓应用加固崩溃日志内容
  {
    name: '',
    loc: '/encrypt/collapse-log',
    key: 'encrypt_collapse_log',
    component: () => import('@/modules/android-encrypt-module/android-encrypt-collapse-log'),
  },
  // 安卓加固渠道设置
  {
    name: '',
    loc: '/encrypt/channel',
    key: 'channel_manage',
    component: () => import('@/modules/android-encrypt-module/android-channel-manage'),
  },
  // 代码混淆
  {
    name: 'codeConfusion',
    key: 'kiwi_compile',
    loc: '/code/confusion',
    component: () => import('@/modules/code-confusion-module/code-confusion'),
  },
  // 代码虚拟化
  {
    name: 'kiwiVirtual',
    key: 'kiwi_virtual',
    loc: '/code/virtual',
    component: () => import('@/modules/code-virtualization-module/code-virtualization'),
  },
  // kiwiguard 应用管理
  {
    name: 'mencelog23',
    key: 'project_manager',
    loc: '/security/project',
    component: () => import('@/modules/kiwiguard-module/application-management'),
  },
  {
    name: 'ios_project_manager',
    key: 'ios_project_manager',
    loc: '/security/ios-project',
    component: () => import('@/modules/kiwiguard-module/ios/application-management'),
  },
  // 威胁概述
  {
    name: 'Summarysp',
    key: 'threaten_summarize',
    loc: '/kiwi-guard/threat-summary',
    component: () => import('@/modules/kiwiguard-module/threat-summary'),
  },
  // 盗版应用
  {
    name: 'piracy-application',
    key: 'pirate_device',
    loc: '/kiwi-guard/piracy-application',
    component: () => import('@/modules/kiwiguard-module/piracy-application'),
  },
  // 虚假设备
  {
    name: 'fake-device',
    key: 'virtual_device',
    loc: '/kiwi-guard/fake-device',
    component: () => import('@/modules/kiwiguard-module/fake-device'),
  },
  // 可疑设备
  {
    name: 'GamePlug',
    loc: '/kiwi-guard/suspicious-equipment',
    key: 'plugin_device',
    component: () => import('@/modules/kiwiguard-module/suspicious-equipment'),
  },
  // 对抗事件
  {
    name: 'confrontationEventevent',
    loc: '/kiwi-guard/confrontation-eventevent',
    key: 'compete_device',
    component: () => import('@/modules/kiwiguard-module/confrontation-event'),
  },
  // 环境风险
  {
    name: 'environmentalRisk',
    loc: '/kiwi-guard/environmental-risk',
    key: 'environment_device',
    component: () => import('@/modules/kiwiguard-module/environmental-risk'),
  },
  // 威胁日志
  {
    name: 'mencelog2',
    key: 'threaten_log',
    loc: '/kiwi-guard/threat-log',
    component: () => import('@/modules/kiwiguard-module/threat-log'),
  },
  // iOS威胁日志
  {
    name: 'ios_threaten_log',
    key: 'ios_threaten_log',
    loc: '/kiwi-guard/ios-threat-log',
    component: () => import('@/modules/kiwiguard-module/ios/threat-log'),
  },
  // 安全策略
  {
    name: 'mencelog3',
    loc: '/security/policy',
    key: 'security_policy',
    component: () => import('@/modules/kiwiguard-module/security-policy'),
  },
  {
    // 崩溃日志
    name: 'crashlog',
    key: 'crash_log',
    loc: '/kiwi-guard/crash-log',
    component: () => import('@/modules/kiwiguard-module/crash-log'),
  },
  // 账号信息
  {
    name: '账号信息',
    key: 'user_info',
    loc: '/user/info',
    component: () => import('@/modules/account-center-module/userInfo'),
  },
  // 账号密码
  {
    name: '账号密码',
    key: 'user_password',
    loc: '/user/modifypwd',
    component: () => import('@/modules/account-center-module/userPassword'),
  },
  // 企业信息
  {
    name: 'companyInfo',
    key: 'enterprise_info',
    loc: '/company/Info',
    component: () => import('@/modules/enterprise-management-module/enterprise-information'),
  },
  // 企业成员
  {
    name: 'memberAdmin',
    key: 'enterprise_user',
    loc: '/company/member',
    component: () => import('@/modules/enterprise-management-module/enterprise-memberse'),
  },
  // 角色成员
  {
    name: 'roleAdmin',
    key: 'enterprise_role',
    loc: '/company/role',
    component: () => import('@/modules/enterprise-management-module/role-management'),
  },
  // 企业客户管理
  {
    name: 'agent',
    key: 'enterprise_manage',
    loc: '/admin/agent',
    component: () => import('@/modules/customer-management-module/enterprise-customer-management'),
  },
  // 用户管理
  {
    name: 'userList',
    key: 'user_manage',
    loc: '/admin/userList',
    component: () => import('@/modules/customer-management-module/user-management'),
  },
  // 登录记录
  {
    name: '/admin/loginRecord',
    key: 'login_log',
    loc: '/admin/loginRecord',
    component: () => import('@/modules/customer-management-module/login-record'),
  },
  // 新 客户管理里面的通知设置
  {
    name: 'notice',
    key: 'message_manage',
    loc: '/admin/notice',
    component: () => import('@/modules/customer-management-module/notice-system-admin')
  },
  // 安全检测管理 (Android)
  {
    name: 'androidDetectionList',
    key: 'android_security_detect_manage',
    loc: '/admin/androidDetectionList',
    component: () => import('@/modules/detection-module/android-detection-admin'),
  },

  // 安全检测管理 (IOS)
  {
    name: 'iosDetectionList',
    key: 'ios_security_detect_manage',
    loc: '/admin/iosDetectionList',
    component: () => import('@/modules/detection-module/ios-detection-admin'),
  },
  // 隐私检测管理
  {
    name: 'privact',
    key: 'privacy_detect_mange',
    loc: '/admin/privacy',
    component: () => import('@/modules/privacy-detection-module/privacy-admin'),
  },
  // 应用审查管理
  {
    name: '',
    key: 'privacy_aws_application_manager',
    loc: '/admin/application',
    component: () => import('@/modules/privacy-detection-module/privacy-application-admin'),
  },
  // Android加固后台管理运营界面
  {
    name: 'adminAppList',
    key: 'android_encrypt_manage',
    loc: '/admin/appList',
    component: () => import('@/modules/android-encrypt-module/android-encrypt-admin'),
  },
  // kiwiguard 后台管理运营界面
  {
    name: 'kiwiguardManage',
    key: 'kiwiguard_manage',
    loc: '/admin/kiwiguard',
    component: () => import('@/modules/kiwiguard-module/kiwiguard-admin'),
  },
  // sdk 加固后台管理运营界面
  {
    name: 'sdkadminAppList',
    key: 'sdk_encrypt_manage',
    loc: '/admin/sdkEncrypt',
    component: () => import('@/modules/sdk-encrypt-module/sdk-encrypt-admin'),
  },
  // 代码混淆运营管理
  {
    name: 'kiwi_compile_manage',
    key: 'kiwi_compile_manage',
    // loc: '/code/confusionAdmin',
    loc: '/encrypt/kiwiVM',
    component: () => import('@/modules/code-confusion-module/code-confusion-admin'),
  },
  // 代码虚拟化运营管理
  {
    name: 'kiwi_virtual_manage',
    key: 'kiwi_virtual_manage',
    // loc: '/code/virtualAdmin',
    loc: '/encrypt/kiwiCM',
    component: () => import('@/modules/code-virtualization-module/code-virtualization-admin'),
  },
  // 检测配置模块
  {
    name: 'config',
    key: 'detections/config',
    loc: 'detections/config',
    component: () => import('@/modules/detection-module/detectionConfig'),
  },
  // 企业收录管理
  {
    name: 'record-enterprise',
    key: 'enterprise_record',
    loc: '/platform/recordEnterprise',
    component: () => import('@/modules/platform_public/record-enterprise'),
  },
  // 应用收录管理
  {
    name: 'record-application',
    key: 'application_record',
    loc: '/platform/recordApplication',
    component: () => import('@/modules/platform_public/record-application'),
  },
  // 应用合规审查看板
  {
    name: 'aws_Secret',
    key: 'privacy_aws_application',
    loc: '/secret/aws',
    component: () => import('@/modules/privacy-detection-module/application-compliance-review-aws'),
  },
  // 警务应用检测
  {
    name: 'aws_Secret',
    key: 'privacy_police_application',
    loc: '/secret/police',
    component: () => import('@/modules/privacy-detection-module/application-compliance-review-police'),
  },

  // ----------------------新隐私检测模块---------------------
  // 新-应用合规审查
  {
    name: 'privacy_application_new',
    key: 'privacy_application_new',
    loc: '/privacy/application',
    component: () => import('@/modules/privacy-detection-module-new/application-compliance-review-new'),
  },

  // 新-归属企业管理
  {
    name: 'privacy_company_new',
    key: 'privacy_company_new',
    loc: '/privacy/company',
    component: () => import('@/modules/privacy-detection-module-new/business-management'),
  },

  // 新-权限规则管理
  {
    name: 'privacy_permission_behavior_new',
    key: 'privacy_permission_behavior_new',
    loc: '/privacy/permission/behavior',
    component: () => import('@/modules/privacy-detection-module-new/rule-config'),
  },

  // 新-隐私文本规则
  {
    name: 'privacy_text_new',
    key: 'privacy_text_new',
    loc: '/privacy/text',
    component: () => import('@/modules/privacy-detection-module-new/text-detection'),
  },

  // 新-应用权限库
  {
    name: 'privacy_permission_new',
    key: 'privacy_permission_new',
    loc: '/privacy/permission',
    component: () => import('@/modules/privacy-detection-module-new/power-config'),
  },

  // 新-隐私行为库
  {
    name: 'privacy_behavior_new',
    key: 'privacy_behavior_new',
    loc: '/privacy/behavior',
    component: () => import('@/modules/privacy-detection-module-new/behavior-config'),
  },

  // 新-SDK基础库
  {
    name: 'privacy_sdk_new',
    key: 'privacy_sdk_new',
    loc: '/privacy/sdk',
    component: () => import('@/modules/privacy-detection-module-new/sdk-bank'),
  },

  // 新-企业SDK库
  {
    name: 'privacy_company_sdk_new',
    key: 'privacy_company_sdk_new',
    loc: '/privacy/companySdk',
    component: () => import('@/modules/privacy-detection-module-new/sdk-bank-company'),
  },

  // 新-隐私审查细则
  {
    name: 'privacy_review_rules_new',
    key: 'privacy_review_rules_new',
    loc: '/privacy/review/rules',
    component: () => import('@/modules/privacy-detection-module-new/detection-detail'),
  },

  // 新-检测模板管理
  {
    name: 'privacy_detection_template_manager_new',
    key: 'privacy_detection_template_manager_new',
    loc: '/privacy/detection/template/manager',
    component: () => import('@/modules/privacy-detection-module-new/detection-template'),
  },

  // 新-企业资产管理
  {
    name: 'privacy_assets',
    key: 'privacy_assets',
    loc: '/privacy/enterprise/assets/management',
    component: () => import('@/modules/privacy-detection-module-new/assets-management'),
  },

  // 新-设备管理
  {
    name: 'privacy_equipment_management',
    key: 'privacy_equipment_management',
    loc: '/privacy/equipment',
    component: () => import('@/modules/privacy-detection-module-new/equipment-admin'),
  },
  // 新-安全检测
  {
    name: 'detection_security_new',
    key: 'security_application_new',
    loc: '/detection/security/application',
    component: () => import('@/modules/privacy-detection-module-new/security-detection'),
  },
  // 新-隐私合规检测
  {
    name: 'detection_privacy_new',
    key: 'privacy_application_detection_new',
    loc: '/detection/privacy/application',
    component: () => import('@/modules/privacy-detection-module-new/privacy-detection'),
  },
  {
    name: 'data_manage_group',
    key: 'data_manage_group',
    loc: '/platform-management/data',
    component: () => import('@/modules/platform-management-module/data'),
  },
  {
    name: 'h5_encrypt',
    key: 'h5_encrypt',
    loc: '/h5-encrypt/encrypt',
    component: () => import('@/modules/h5-encrypt-module/android-encrypt'),
  },
  {
    name: 'h5_encrypt_policy',
    key: 'h5_encrypt_policy',
    loc: '/h5-encrypt/encrypt-policy',
    component: () => import('@/modules/h5-encrypt-module/encrypt-policy-new'),
  },
  // 新-web安全检测
  // 公众号检测
  {
    name: 'web_scan_detection',
    key: 'web_scan_detection',
    loc: '/web/official-account',
    component: () => import('@/modules/web-applet-scan-module/web-official-account-detection')
  },
  // 小程序检测
  {
    name: 'web_applet_detection',
    key: 'web_applet_detection',
    loc: '/applet/detection',
    component: () => import('@/modules/web-applet-scan-module/applet-detection')
  },
  // 小程序加固
  {
    name: 'web_encrypt_detection',
    key: 'web_encrypt_detection',
    loc: '/applet/encrypt',
    component: () => import('@/modules/web-applet-scan-module/applet-encrypt')
  },
  // 小程序加固策略
  {
    name: 'applet_encrypt_policy',
    key: 'applet_encrypt_policy',
    loc: '/applet/encryptPolicy',
    component: () => import('@/modules/web-applet-scan-module/applet-encrypt-policy')
  },
  // 鸿蒙虚拟化
  {
    name: 'harmony_encrypt',
    key: 'harmony_encrypt_virtualization',
    loc: '/harmony/confusion',
    component: () => import('@/modules/ohos-encrypt-module/ohos-confusion')
  },
  // 鸿蒙NEXT应用加固
  {
    name: 'harmony_ark_encrypt',
    key: 'harmony_encrypt_application_reinforcement',
    loc: '/harmony/next_arkconfusion',
    component: () => import('@/modules/ohos-encrypt-module/ohos-ark-confusion')
  }
];
